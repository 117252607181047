/*@import url('https://fonts.googleapis.com/css?family=Open+Sans');*/
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url("./fonts/Montserrat-VariableFont_wght.ttf") format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif !important;
}
body, body *:not(i, .ms-Pivot-icon){
  font-family: 'Montserrat', sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
div[role="radiogroup"] label {
  color: #768692 !important;
}
div[role="radiogroup"] label::after {
  border-color: #768692 !important;
}
div[role="radiogroup"] label::before {
  border-color: #768692 !important;
}
div[role="radiogroup"] label.is-checked  {
  color: #0032A0 !important;
}
div[role="radiogroup"] label.is-checked::after  {
  border-color: #0032A0 !important;
}
div[role="radiogroup"] label.is-checked::before  {
  border-color: #0032A0 !important;
}
.inlineflex .ms-ChoiceField{
  display: inline-block;
  margin-left: 15px;
  margin-top: 5px;
}
.inputNumPacientes {
  width: calc(100% - 75px);
  margin-right: 5px;
  display: inline-block;
}
.inputNumPacientes .ms-TextField-fieldGroup {
  border: none;
}
.inputNumPacientes .ms-TextField-fieldGroup {
  border: none;
}
.inputNumPacientes .ms-TextField-fieldGroup::after {
  border: none;
  outline: none;
}
.inputNumPacientes .ms-TextField-fieldGroup input {
  text-align: right;
  outline: none;
}
.inputNumPacientes.is-active .ms-TextField-fieldGroup input {
  background-color: #f4f4f4;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/**
#7a99ac
*/
.show-cell {
  background: white;
  border-left: 1px solid lightgrey !important;
  border-right: 1px solid lightgrey !important;
  border-bottom: 1px solid lightgrey !important;
}

.show-name {
  font-weight: bold;
}

.show-presenter {
  font-style: italic;
}

#root{
  background-image: url(./images/Rectangle.png);
  background-repeat: repeat-y;
  background-size: 100% auto;
  /*border-top: 10px solid #F9423A;*/
}

.menuPrincipal button.is-selected span {color: #0032A0}
.menuPrincipal span {font-size: 16px !important; color: #768692;}
.menuPrincipal button:not(button:last-child) span.ms-Pivot-text {
  border-left: 6px solid #fff;
  padding-left: 3px;
  line-height: normal;
  vertical-align: initial;
}
.menuPrincipal button:hover:not(button:last-child) span.ms-Pivot-text {
  border-left: 6px solid #768692;
  padding-left: 3px;
  line-height: normal;
  vertical-align: initial;
}
.menuPrincipal button.is-selected:not(button:last-child) span.ms-Pivot-text {
  border-left: 6px solid #F9423A;
  padding-left: 3px;
  line-height: normal;
  vertical-align: initial;
}
.menuPrincipal button:last-child {
  background-color: #0032A0;
  border-radius: 2px;
  padding: 5px 12px !important;
  height: 32px;
  line-height: 22px;
  top: 6px;
  float: right;
}
.menuPrincipal button:last-child:hover {
  background-color: #0032A0 !important;
}
.menuPrincipal button:last-child span {
  color: #fff;
}
.menuPrincipal button:last-child span.ms-Pivot-icon {
  background-color: #fff;
  color: #0032A0;
  padding: 4px 4px 1px;
  border-radius: 33px;
  vertical-align: middle;
  margin-right: 5px;
  font-size: 11px !important;
}
.menuPrincipal button:last-child span.ms-Pivot-text {
  vertical-align: middle;
  font-size: 14px !important;
}
.menuPrincipal *::before {content: none;}
.menuParams button.is-selected span {color: #0032A0; font-weight: 400;}
.menuParams span {color: #768692;}
.menuParams *::before {height: 1px;}
.menuPrincipal {
  overflow-x: auto;
  overflow-y: hidden;
}
.menuParams {
  overflow-x: auto;
  overflow-y: hidden;
}

button.btn-icono {
  /*border: 1px solid #768692;*/
  border-radius: 25px;
  padding: 20px;
  color: #768692;
  margin-top: 8px;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.5),0 .3px .9px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
}

label {color: #0032A0 !important; font-weight: 400 !important;}
div .labelGris {color: #768692 !important; font-weight: 400 !important;}
div .labelGris label {color: #768692 !important; font-weight: 400 !important;}

.separadorGris{
  border: none;
  height: 1px;
  background-color: #EAEAEA;
}

input:disabled {
  background-color: #7686921A;
}

#customers {
  font-family: 'Montserrat', sans-serif !important;
  border-collapse: collapse;
  width: 100%;
}

#customers td {
  border: 1px solid #768692;
  padding: 8px;
}

#customers th{
  border-top: 1px solid #768692;
}

#customers:nth-child(1) > tr:nth-child(1) > th:nth-child(1) {
	border-left: 1px solid #768692;
}

#customers:nth-child(1) > tr:nth-child(1) > th:nth-child(4) {
	border-right: 1px solid #768692;
}
#customers2:nth-child(1) > tr:nth-child(1) > th:last-child {
  border-right: 1px solid #7a99ac;
}

.errorInput {
  border: 1px solid red;
}


#customers tr:nth-child(even){background-color: #e8eaeb;}



#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
}

#customers2 {
  font-family: 'Montserrat', sans-serif !important;
  border-collapse: collapse;
  width: 100%;
}

#customers2 td {
  border: 1px solid #7a99ac;
  padding: 8px;
}

#customers2 th{
  border-top: 1px solid #7a99ac;
}

#customers2:nth-child(1) > tr:nth-child(1) > th:nth-child(1) {
	border-left: 1px solid #7a99ac;
}

#customers3 {
  font-family: 'Montserrat', sans-serif !important;
  border-collapse: collapse;
  width: calc(100% - 1px);
  margin-left: 1px;
}

#customers3 td {
  border: 1px solid #7a99ac;
  padding: 8px;
}

#customers3 th{
  border-top: 1px solid #7a99ac;
}

#customers3 > tr:nth-child(1) > th:first-child {
	border-left: 1px solid #7a99ac;
}
#customers3:nth-child(1) > tr:nth-child(1) > th:nth-child(2) {
  border-left: 1px solid #7a99ac;
}
#customers3 > tr:nth-child(2) > th:first-child {
	border-left: 1px solid #7a99ac !important;
}
#customers3 > tr:nth-child(2) > th:last-child {
	border-right: 1px solid #7a99ac !important;
}
#customers3:nth-child(1) > tr:nth-child(1) > th:last-child {
  border-right: 1px solid #7a99ac;
}


#customers tr:nth-child(even){background-color: #e8eaeb;}



#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
  text-align: center;
}

/* label, .ms-Dropdown-caretDown{
  color: #7a99ac!important;
} */
.ms-TextField-fieldGroup, .ms-Dropdown, .ms-Dropdown-title{
  border-color: #768692!important;
}
.ms-TextField-suffix{
  background-color: #768692!important;
  color: #ffffff!important;
}
.ms-TextField.is-active .ms-TextField-suffix {
  background-color: #0032A0!important;
}

.ms-Checkbox-checkbox, .ms-Checkbox-text{
  border-color: #768692!important;
  color: #768692!important;
}
hr{
  color: #768692;
}
.lineaHr {
  border: none;
  height: 1px;
  background-color: #0032a0;
  display: inline-block;
  width: calc(100% - 28px);
  vertical-align: middle;
}
.lineaHrNaranja {
  border: none;
  height: 5px;
  background-color: #F9423A;
  display: inline-block;
  width: 28px;
  vertical-align: middle;
}
.lineaHrNaranjaFina {
  border: none;
  height: 2px;
  background-color: #F9423A;
  display: block;
  margin: 5px 0px 0px;
}
.divNaranja {
  width: 17px;
  height: 17px;
  background-color: #F9423A;
}

.ms-Pivot-text{
  font-size: 14px!important;
}
.ms-Pivot-link{
  padding-left: 10px!important;
  padding-right: 10px!important;
}
.ms-Pivot-link:hover{
  background-color: rgba(243, 242, 241, 0)!important;
}

.ms-TooltipHost{
  color: #707c8c;
  font-weight: 600;
}
/*
.ms-Breadcrumb-listItem{
  text-shadow: 1px 0 0 rgba(255, 255, 255, 0.815), -1px 0 0 rgba(255, 255, 255, 0.815), 0 1px 0 rgba(255, 255, 255, 0.815), 0 -1px 0 rgba(255, 255, 255, 0.815), 1px 1px rgba(255, 255, 255, 0.815), -1px -1px 0 rgba(255, 255, 255, 0.815), 1px -1px 0 rgba(255, 255, 255, 0.815), -1px 1px 0 rgba(255, 255, 255, 0.815);
}
*/

.ms-Button{
  /*border-radius: 40px;*/
  font-family: 'Montserrat', sans-serif !important;
  
}
.ms-Dropdown-items button[aria-selected="true"] {
  background-color: #76869230;
}
.ms-Button--primary{
  font-weight: 600 !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
}

#menuADM > div > button > span > span > span{
  font-size: 18px!important;
}

.ms-Breadcrumb-item{
  font-size: 14px!important;
}