@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url("./fonts/Montserrat-VariableFont_wght.ttf") format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', sans-serif;
}
